/**
 * Declare Variable
 */
const state = {
  channel: null,

  channelsPageState: {
    bottom: false,
    page: 1,
    itemsPerPage: 25,
    searchTerm: "",
    channels: [],
    reachedEnd: false,
  },
};

const getters = {
  channelId: state => state.channel?.Id || 0,
  getChannel: (state) => state.channel,
  getChannelsPageState: (state) => state.channelsPageState,
};

const actions = {};

const mutations = {
  setChannel: (state, newChannel) => {
    state.channel = newChannel;
  },

  setChannelsPageState: (state, newPageState) => {
    state.channelsPageState = newPageState;
  },
};

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
