<template>
  <div id="app">
    <router-view key="ready" />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { eraseCookie, getCookie, setCookie } from './utils/browserStorage'

import loginProvider from '@/providers/login'
import profileProvider from '@/providers/profile'

//const TopBar = () => import('@/components/MainSiteElements/TopBar')

export default {
  components: {
    //'top-bar': TopBar,
  },

  data() {
    return {
      signalRIsSetup: false,
    }
  },

  computed: {
    ...mapState(['appStartupLoadReady']),
    ...mapState('channelStore', ['channel']),
    ...mapState('profileStore', ['isLoggedIn']),
    ...mapGetters('channelStore', ['channelId'])
  },

  watch: {
    'channelId': {
      immediate: true,
      handler: function (value) {
        this.showHideJsdWidget(value)
      }
    }
  },

  beforeCreate() {
    this.$nextTick().then(() =>
      document.documentElement.classList.add('frontend-environment')
    )
  },

  created() {
    let cMT = getCookie('mt')
    if (cMT === '') {
      setCookie('mt', this.generateGuid(), 100, true)
    }

    let t = setTimeout(() => {
      this.getProfileTokenFromCookie()
      clearTimeout(t)
    }, 100)
  },

  mounted() {
    this.loadSupportWidget(function (_this) {
      let DOMContentLoaded_event = document.createEvent('Event')
      DOMContentLoaded_event.initEvent(
        'DOMContentLoaded',
        true,
        true
      )
      window.document.dispatchEvent(DOMContentLoaded_event)
      const t = setTimeout(() => {
        _this.showHideJsdWidget(_this.channelId)
        clearTimeout(t)
      }, 50);
    })
  },

  methods: {
    ...mapActions('profileStore', ['logoff', 'saveProfileToken']),

    showHideJsdWidget(channelId) {
      const JSD_WIDGET = document.getElementById('jsd-widget')
        if(!channelId && JSD_WIDGET !== null) {
          JSD_WIDGET.style.right = '-100px'
        }
        if(channelId && JSD_WIDGET !== null) {
          JSD_WIDGET.style.right = '5px'
        }
    },

    loadSupportWidget(callback) {
      let _this = this
      let widgetScript = document.createElement('script')
      widgetScript.type = 'text/javascript'
      widgetScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js'
      widgetScript.setAttribute('data-jsd-embedded', null)
      widgetScript.setAttribute(
        'data-key',
        'd677c6e5-c14b-4070-823f-8cca41d2589d'
      )
      widgetScript.setAttribute(
        'data-base-url',
        'https://jsd-widget.atlassian.com'
      )
      widgetScript.async = true
      widgetScript.onload = () => {
        callback(_this)
      }

      document.head.appendChild(widgetScript)
    },

    generateGuid() {
      return (
        this.s4() +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        '-' +
        this.s4() +
        this.s4() +
        this.s4()
      )
    },

    s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    },

    getProfileTokenFromCookie() {
      let apCookie = getCookie('ap')
      let ltCookie = getCookie('lt')

      if (apCookie === '' || ltCookie === '') {
        this.logoff()

        // Update app startup load status
        this.$store.dispatch('updateAppStartupLoadStatus', 'profileData')
        this.$store.dispatch('updateAppStartupLoadStatus', 'verifyProfileToken')
      } else {
        if (ltCookie !== '') {
          // Verify if token is still valid
          this.verifyProfileToken(ltCookie)
        }
      }
    },

    verifyProfileToken(profileToken) {
      if (profileToken) {
        loginProvider.methods
          .verifyProfileToken(profileToken)
          .then((response) => {
            if (response.status === 200) {
              if (response.data) {
                this.saveProfileToken(profileToken)
              } else {
                this.logoff()
              }
            }
          })
          .catch((e) => { })
          .finally(() => { })
      }
    },
  },
}
</script>

<style lang="scss">
@import "./assets/styles/main.scss";
</style>
